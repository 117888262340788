<div class="scroll-land">
  <div (click)="onScrollClick()" class="scroll-bottom-container">
    <p class="text primary-main font-regular-medium">SCROLL</p>
    <div class="arrow">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
